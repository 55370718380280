import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Markdown from '../../lib/SimpleContent/Markdown';

const Information = () => (
  <Card>
    <CardContent>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <div className="box">
            <Markdown mdKey="information" />
          </div>
        </div>
        <div className="col-xs-12 col-sm-6">
          <div className="box">
            <iframe
              title="Stade de France - Porte T"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3117.4842765165035!2d2.358563410655109!3d48.925272944883346!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNDjCsDU1JzI2LjkiTiAywrAyMScyOC44IkU!5e0!3m2!1sfr!2sfr!4v1530794536235"
              width="100%"
              height="300"
              allowFullScreen
              frameBorder="0"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div
          className="col-xs-12"
          style={{ color: 'rgb(229,0,81)', fontWeight: 'bold', marginTop: 40 }}
        >
          <Markdown mdKey="information" attr="footer" />
        </div>
      </div>
    </CardContent>
  </Card>
);

export default Information;
